
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: 'LibreBaskerville';
  src: url('/Users/apple/Downloads/fdss-main 3/public/fonts/LibreBaskerville-Regular.ttf') format('truetype');
  
  font-style: normal;
}
@font-face {
  font-family: 'Signika';
  src: url('/Users/apple/Downloads/fdss-main 3/public/fonts/Signika-VariableFont_GRAD,wght.ttf') format('truetype');
  
  font-style: normal;
}
@font-face {
  font-family: 'Agdasima';
  src: url('/Users/apple/Downloads/fdss-main 3/public/fonts/Agdasima-Regular.ttf') format('truetype');
  
  font-style: normal;
}
body {
  font-family: 'LibreBaskerville', sans-serif;
}

.LibreBaskerville{
  font-family: 'LibreBaskerville';
}
.Signika{
  font-family: 'Signika';
}
.Agdasima{
  font-family: 'Agdasima'
}