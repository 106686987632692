.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.dropdown-icon {
  margin-right: 8px;
}

.dropdowncontent {
  display: flex;
  flex-direction: column;
}

.dropdown-links {
  margin: 4px 0;
  color: inherit;
  text-decoration: none;
}

.center-text {
  text-align: center;
}