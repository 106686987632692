#slider-container{
  background-color: aqua;
}
@font-face {
  font-family: 'LibreBaskerville';
  src: url('/Users/apple/Downloads/fdss-main 3/public/fonts/LibreBaskerville-Regular.ttf') format('truetype');
  
  font-style: normal;
}
body {
  font-family: 'LibreBaskerville', sans-serif;
}
.LibreBaskerville{
  font-family: 'LibreBaskerville';
}