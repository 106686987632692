/* src/Dropdown.css */
.dropdown-menu {
    display: block;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    z-index: 1000; /* Ensure the dropdown appears on top */
    width: 300px;
    padding: 10px;
    border-radius: 8px;
    display: none; /* Initially hidden */
  }
  .blog{
    font-family: "DM Sans", sans-serif;
    font-size: 0.9rem;
  }
  
  .dropdown-item {
    display: flex;
    align-items: flex-start;
    padding: 5px;
    
  }
  
  .dropdown-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .dropdown-links {

    color: #000;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
  }
  
  .dropdown-links + p {
    font-family: "DM Sans", sans-serif;
    color: #555;
    font-size: 0.9rem;
    margin: 0;
  }
  .p{
    margin-top: 5px;;
  }
  
  .dropdown-links:hover {
    color: #007BFF;
  }
  
  /* Show the dropdown menu when parent has `show` class */
  .nav-item.show .dropdown-menu {
    display: block;
  }
  .blogs{
    display: grid;
  }