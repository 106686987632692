.navbar {
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #eee;
  border-radius: 25px;
  margin-top: 4px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  
  padding: 0 24px;
  border-radius: 25px;
}

.navbar-logo {
  color: #000;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.navbar-logo-img {
  height: 40px;
  margin-right: 10px;
}

.menu-icon {
  display: none;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 100px;
}

.nav-item {
  height: 80px;
  position: relative;
  margin-right: 20px;
}

.nav-links {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-size: 1rem;
}

.nav-links:hover {
  color: #007BFF;
}

.nav-menu.active {
  display: flex;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #fff;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #ddd;
    border-radius: 0;
  }
}